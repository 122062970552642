//Dependencies
import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

//Assets
import unavailableImage from "../images/unavailable-image.jpg";
import "../stylesheets/imageUploader.css";
import RemoveIcon from "@material-ui/icons/DeleteOutline";
import ErrorIcon from "@material-ui/icons/Error";
import { Tooltip, IconButton } from "@material-ui/core";

import "@google/model-viewer";
import firebase from "firebase";
import "@firebase/firestore";
import "@firebase/auth";

const VideoUploader = ({
  inputName,
  video,
  handleSetValue,
  readOnly = false,
}) => {
  const [entityVideo, setEntityVideo] = useState(null);
  const [oversize, setOversize] = useState(false);
  const [isYoutube, setIsYoutube] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (!!video) {
      if(video.includes('gs://cartear-f8ceb.appspot.com')) {
        handleSetPreview3DModel(video)
      } else {
        setEntityVideo(video);
        setIsYoutube(video.includes('youtu.be'))
      }
    } else {
    }
  }, [video]);

  

  const handleVideo = (e) => {
    const reader = new FileReader();
    const size = e.target.files[0].size;

    if (size > 8000000) {
      setOversize(true);
    } else {
      setOversize(false);
      reader.onload = () => {
        if (reader.readyState === 2) {
          setEntityVideo(URL.createObjectURL(e.target.files[0]));
          handleSetValue(inputName, reader.result);
        }
      };

      try {
        reader.readAsDataURL(e.target.files[0]);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleRemoveVideo = () => {
    const inputFile = document.querySelector(`#upload-${inputName}`);
    inputFile.value = null;
    setEntityVideo(null);
    setIsYoutube(false)
    handleSetValue(inputName, "");
  };

  const handleSetPreview3DModel = (video) => {
    const MODEL_PATH = video?.split("gs://cartear-f8ceb.appspot.com/")[1];

    const CONFIG = {
      apiKey: "AIzaSyDnPB9bWEvQg3R-89rBLXEPz4B7M0uH69U",
      authDomain: "cartear-f8ceb.firebaseapp.com",
      databaseURL: "https://cartear-f8ceb.firebaseio.com",
      storageBucket: "cartear-f8ceb.appspot.com",
    };

    if (firebase.apps.length === 0) firebase.initializeApp(CONFIG);

    const STORAGE = firebase.storage();
    const STORAGE_REF = STORAGE.ref();
    const TANG_REF = STORAGE_REF.child(MODEL_PATH);

    firebase
      .auth()
      .signInAnonymously()
      .then(function () {
        TANG_REF.getDownloadURL()
          .then((url) => {
            setEntityVideo(url);
          })
          .catch((error) => {
            console.error(error);
          });
      });
  };

  return (
    <div>
      <section className="entity-previewimage-container">
        {oversize && (
          <Tooltip
            title="Máximo tamaño permitido 8MB"
            className="button-remove-previewimage"
          >
            <ErrorIcon style={{ color: "red" }} />
          </Tooltip>
        )}
        {!readOnly && entityVideo !== null && (
          <span
            className="button-remove-previewimage"
            onClick={() => handleRemoveVideo()}
          >
            <RemoveIcon />
          </span>
        )}
        { !isYoutube && (
          <video
            controls
            src={entityVideo || ""}
            className="entity-previewimage"
          ></video>
        )}
        { isYoutube && (
          <iframe
            controls
            src={(isYoutube ? `https://www.youtube.com/embed/${entityVideo.split("be/")[1]}` : entityVideo) || ""}
            className="entity-previewimage"
          />
        )}
        <input
          type="file"
          id={`upload-${inputName}`}
          name={inputName}
          accept="video/*"
          onChange={handleVideo}
          ref={inputRef}
        />
        {!readOnly && (
          <section
            className="upload-previewimage-button"
            onClick={() => {
              inputRef.current.click();
            }}
          >
            AÑADIR VIDEO
          </section>
        )}
      </section>
    </div>
  );
};

export default VideoUploader;
